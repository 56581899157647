import api from '@/plugins/api'

const address = {
  search: async function (address) {
    try {
      const response = await api.post('/maps/auto-complete', address)
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
}

export default address