import api from '@/plugins/api'

export const payments = {
  load: async function (filters) {
    try {
      const response = await api.get('/clinics', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  simplified: async function () {
    try {
      const response = await api.get('/clinics/simplified')
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  details: async function (id) {
    try {
      const response = await api.get(`/clinics/${id}`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  create: async function (formData) {
    try {
      const response = await api.post('/clinics', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  update: async function (id, formData) {
    try {
      formData.append('_method', 'PATCH');
      const response = await api.post(
        `/clinics/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  remove: async function (id) {
    try {
      await api.delete(`/clinics/${id}`)
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
}

export default payments