<template>
  <v-row>
    <v-col cols="9" class="py-0">
      <v-menu offset-y v-model="matchesMenu" content-class="v-menu-input-text">
        <template v-slot:activator="{}">
          <validation-provider
            v-slot="{ errors }"
            :vid="`address-${iterationKey}-address`"
            :name="$t('address').toLowerCase()"
            :rules="{
              required: true,
            }"
          >
            <v-text-field
              v-model="address.address"
              outlined
              :label="$t('address')"
              :error-messages="errors"
              :disabled="disabled"
              @keyup="searchMatches"
            />
          </validation-provider>
        </template>
        <v-list>
          <v-list-item
            v-for="item in matches"
            :key="item.id"
            @click.prevent="chooseMatch(item)"
          >
            <v-list-item-title>{{ `${item.formatted_address}` }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="3" class="py-0">
      <v-text-field
        v-model="address.apartment"
        outlined
        :label="$t('apto-no')"
        :disabled="disabled"
      />
    </v-col>
    <v-col cols="9" class="py-0">
      <validation-provider
        v-slot="{ errors }"
        :vid="`address-${iterationKey}-country`"
        :name="$t('country').toLowerCase()"
        :rules="{
          required: true,
        }"
      >
        <v-select
          v-model="address.country"
          outlined
          :label="$t('country')"
          :items="countries"
          return-object
          item-value="id"
          item-text="name"
          :error-messages="errors"
          :disabled="disabled"
          @change="selectedCountry(country)"
        >
          <template #selection="{ item }">
            {{ item.name }}
          </template>
          <template #item="{ item }">
            {{ item.name }}
          </template>
        </v-select>
      </validation-provider>
    </v-col>
    <v-col cols="3" class="py-0">
      <validation-provider
        v-slot="{ errors }"
        :vid="`address-${iterationKey}-zip`"
        :name="$t('zip').toLowerCase()"
        :rules="{
          required: true,
        }"
      >
        <v-text-field
          v-model="address.zip_code"
          outlined
          :label="$t('zip')"
          :error-messages="errors"
          :disabled="disabled"
        />
      </validation-provider>
    </v-col>
    <v-col cols="6" class="py-0">
      <validation-provider
        v-slot="{ errors }"
        :vid="`address-${iterationKey}-state`"
        :name="$t('state').toLowerCase()"
        :rules="{
          required: true,
        }"
      >
        <v-autocomplete
          v-model="address.state"
          outlined
          :label="$t('state')"
          :items="states"
          item-value="id"
          item-text="name"
          return-object
          :error-messages="errors"
          :disabled="disabled"
        >
          <template #selection="{ item }">
            {{ item.name }}
          </template>
          <template #item="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
      </validation-provider>
    </v-col>
    <v-col cols="6" class="py-0">
      <validation-provider
        v-slot="{ errors }"
        :vid="`address-${iterationKey}-city`"
        :name="$t('city').toLowerCase()"
        :rules="{
          required: true,
        }"
      >
        <v-text-field
          v-model="address.city"
          outlined
          :label="$t('city')"
          :error-messages="errors"
          :disabled="disabled"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import countries from "../../requests/countries";
import address from "../../requests/address";

export default {
  mixins: [],
  props: {
    baseAddress: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iterationKey: {
      type: String,
      default: "",
    },
  },
  components: {},
  data: () => ({
    address: {
      address: "",
      apartment: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
      formatted_address: "",
    },
    countries: [],
    states: [],
    matches: [],
    matchesMenu: null,
    timeout: null,
    lastSearch: null,
  }),
  computed: {},
  watch: {
    address: {
      handler: function () {
        this.$emit("changed", this.address);
      },
      deep: true,
    },
    baseAddress: {
      handler: async function (value) {
        const newAddress = {};
        newAddress.address = value.address;
        newAddress.apartment = value.apartment;
        newAddress.city = value.city;
        newAddress.zip_code = value.zip_code;
        if (value.country_id != "") {
          newAddress.country = await countries.matchById(
            this.countries,
            value.country_id
          );
        } else {
          newAddress.country = this.countries[0];
        }
        await this.selectedCountry(newAddress.country);
        if (value.state_id != "") {
          newAddress.state = await countries.matchById(this.states, value.state_id);
        } else {
          newAddress.state = this.states[0];
        }
        if (
          this.address.address != newAddress.address ||
          this.address.apartment != newAddress.apartment ||
          this.address.city != newAddress.city ||
          this.address.zip_code != newAddress.zip_code ||
          this.address.country_id != newAddress.country_id ||
          this.address.state_id != newAddress.state_id
        ) {
          this.address = newAddress;
        }
      },
      deep: true,
    },
  },
  created: async function () {
    this.countries = await this.$store.dispatch("location/loadCountries");
    this.address.country = this.countries[0];
    this.selectedCountry(this.address.country);
  },
  methods: {
    chooseMatch: async function (item) {
      this.address.address = `${item.street_number} ${item.street}`;
      this.address.city = item.city;
      this.address.zip_code = item.zip_code;
      this.address.formatted_address = item.formatted_address;
      if (item.state != "") {
        for (const entry of this.states) {
          if (entry.name == item.state) {
            this.address.state = entry;
            break;
          }
        }
      }
    },
    searchMatches: async function () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        const searchParams = {
          address: this.address.address,
          apartment: this.address.apartment,
          country: this.address.country ? this.address.country.sortname : null,
          state: this.address.state ? this.address.state.name : null,
          city: this.address.city,
          zip_code: this.address.zip_code,
        };
        if (JSON.stringify(this.lastSearch) != JSON.stringify(searchParams)) {
          this.lastSearch = searchParams;
          const results = await address.search(searchParams);
          this.matches = results;
        }
        if (this.matches.length > 0) {
          this.matchesMenu = true;
        }
      }, 1000);
    },
    selectedCountry: async function (country) {
      if (!country) {
        return;
      }
      if (this.states.length > 0) {
        if (this.states[0].country_id === country.id) {
          return;
        }
      }
      const states = await this.$store.dispatch("location/loadCountryStates", country.id);
      this.states = states;
      this.address.state = states[0];
    },
  },
};
</script>

<style lang="scss" scoped></style>
