<template>
  <v-container fluid class="py-0">
    <v-form @submit.prevent="submit">
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <v-progress-linear color="#1D3557" height="6px" :value="50 * step" />
              <h4 class="mt-2">
                {{ `${$t(`clinics-.${clinic ? "edit" : "new"}`)} (${step}/2)` }}
              </h4>
            </v-col>
          </v-row>
          <div :class="`${step == 1 ? '' : 'd-none'}`">
            <validation-observer v-slot="{ valid }">
              <v-row>
                <v-col cols="12" class="py-0">
                  <h4 class="mt-7">{{ $t("clinics-.details") }}</h4>
                  <p class="mb-5">{{ $t("clinics-.data-sub-heading") }}</p>
                </v-col>
                <v-col cols="6" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('name').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-text-field
                      v-model="request.name"
                      outlined
                      :label="$t('name')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('company').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-text-field
                      v-model="request.company"
                      outlined
                      :label="$t('company')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('logo').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-file-input
                      v-model="request.logo"
                      outlined
                      persistent-hint
                      :hint="$t('max-file-upload')"
                      prepend-icon=""
                      show-size
                      :label="$t('attach-logo')"
                      :error-messages="errors"
                    >
                      <template #prepend-inner>
                        <v-img
                          max-width="20px"
                          :src="require('@/assets/file-upload.svg')"
                        />
                      </template>
                    </v-file-input>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <h4 class="mt-2 mb-4">{{ $t("location") }}</h4>
                </v-col>
                <v-col cols="12">
                  <clinic-address
                    @changed="syncAddress($event)"
                    :baseAddress="baseAddress"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <h4 class="mt-2 mb-4">{{ $t("manager") }}</h4>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('fullname').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-text-field
                      v-model="request.manager.name"
                      outlined
                      :label="$t('fullname')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('email').toLowerCase()"
                    :rules="{ required: true, email: true }"
                  >
                    <v-text-field
                      v-model="request.manager.email"
                      outlined
                      :label="$t('email')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('phone-number').toLowerCase()"
                    :rules="{ required: true }"
                  >
                    <v-text-field
                      v-model="request.manager.phone"
                      outlined
                      :label="$t('phone-number')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6"> </v-col>
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    type="button"
                    :disabled="!valid"
                    elevation="0"
                    block
                    @click.prevent="step += 1"
                  >
                    {{ $t("next") }}
                  </v-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </div>
          <div :class="`${step == 2 ? '' : 'd-none'}`">
            <validation-observer v-slot="{ valid }">
              <v-row>
                <v-col cols="12" class="py-0 mt-5">
                  <h4 class="">{{ $t("system-account") }}</h4>
                  <p class="mb-5">{{ $t("managers-.config-credentials") }}</p>
                </v-col>
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('email').toLowerCase()"
                    :rules="{ required: true, email: true }"
                  >
                    <v-text-field
                      v-model="request.user.email"
                      outlined
                      :label="$t('email')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col v-if="clinic == null" cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('password').toLowerCase()"
                    :rules="{ required: clinic == null }"
                  >
                    <v-text-field
                      v-model="request.user.password"
                      type="password"
                      outlined
                      :label="$t('password')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <v-btn elevation="0" block @click.prevent="step -= 1">
                    {{ $t("previous") }}
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="!valid"
                    elevation="0"
                    block
                  >
                    {{ $t("submit") }}
                  </v-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import clinics from "../../requests/clinics";
import ClinicAddress from "../components/Address";

export default {
  mixins: [],
  components: {
    ClinicAddress,
  },
  data: () => ({
    clinic: null,
    baseAddress: null,
    step: 1,
    request: {
      name: "",
      logo: null,
      address: "",
      apto: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
      formatted_address: "",
      manager: {
        name: "",
        email: "",
        phone: "",
      },
      user: {
        email: "",
        password: "",
      },
    },
  }),
  computed: {},
  watch: {},
  created: async function () {},
  mounted: async function () {
    if (this.$route.params.id) {
      try {
        const clinic = await clinics.details(this.$route.params.id);
        this.request.name = clinic.name;
        this.request.company = clinic.company;
        this.request.manager.email = clinic.manager_email;
        this.request.manager.name = clinic.manager_name;
        this.request.manager.phone = clinic.manager_phone;
        this.request.user.email = clinic.user.email;

        const baseAddress = {};
        baseAddress.address = clinic.address;
        baseAddress.country_id = clinic.country.id;
        baseAddress.state_id = clinic.state.id;
        baseAddress.apto = clinic.apartment;
        baseAddress.city = clinic.city;
        baseAddress.zip_code = clinic.zip_code;
        this.baseAddress = baseAddress;
        this.clinic = clinic;
      } catch (e) {
        this.$router.push({ name: "clinics" });
      }
    }
  },
  methods: {
    syncAddress: async function (address) {
      if (!address) {
        return;
      }
      this.request.address = address.address;
      this.request.apto = address.apto ?? "";
      this.request.country = address.country ? address.country.id : "";
      this.request.state = address.state ? address.state.id : "";
      this.request.city = address.city;
      this.request.zip_code = address.zip_code;
      this.request.formatted_address = address.formatted_address;
    },
    submit: async function () {
      try {
        let response = null;
        var formData = new FormData();
        if (!this.clinic) {
          formData.append("name", this.request.name);
          formData.append("company", this.request.company);
          if (this.request.logo) {
            formData.append("logo", this.request.logo);
          }
          formData.append("manager[email]", this.request.manager.email);
          formData.append("manager[name]", this.request.manager.name);
          formData.append("manager[phone]", this.request.manager.phone);
          formData.append("user[email]", this.request.user.email);
          formData.append("user[password]", this.request.user.password);
          formData.append("address", this.request.address);
          formData.append("country", this.request.country);
          formData.append("state", this.request.state);
          formData.append("apto", this.request.apto);
          formData.append("city", this.request.city);
          formData.append("zip_code", this.request.zip_code);
          response = await clinics.create(formData);
        } else {
          let submit = false;
          if (this.request.name != this.clinic.name) {
            submit = true;
            formData.append("name", this.request.name);
          }
          if (this.request.company != this.clinic.company) {
            submit = true;
            formData.append("company", this.request.company);
          }
          if (this.request.logo) {
            submit = true;
            formData.append("logo", this.request.logo);
          }
          if (this.request.manager.email != this.clinic.manager_email) {
            submit = true;
            formData.append("manager[email]", this.request.manager.email);
          }
          if (this.request.manager.name != this.clinic.manager_name) {
            submit = true;
            formData.append("manager[name]", this.request.manager.name);
          }
          if (this.request.manager.phone != this.clinic.manager_phone) {
            submit = true;
            formData.append("manager[phone]", this.request.manager.phone);
          }
          if (this.request.user.email != this.clinic.user.email) {
            submit = true;
            formData.append("user[email]", this.request.user.email);
          }
          if (this.request.address != this.clinic.address) {
            submit = true;
            formData.append("address", this.request.address);
          }
          if (this.request.country != this.clinic.country.id) {
            submit = true;
            formData.append("country", this.request.country);
          }
          if (this.request.state != this.clinic.state.id) {
            submit = true;
            formData.append("state", this.request.state);
          }
          if (this.request.apto != this.clinic.apartment) {
            submit = true;
            formData.append("apto", this.request.apto);
          }
          if (this.request.city != this.clinic.city) {
            submit = true;
            formData.append("city", this.request.city);
          }
          if (this.request.zip_code != this.clinic.zip_code) {
            submit = true;
            formData.append("zip_code", this.request.zip_code);
          }
          if (submit) {
            response = await clinics.update(this.clinic.id, formData);
          }
        }
        if (response) {
          this.$router.push({ name: "clinics-details", params: { id: response.id } });
          await this.$store.dispatch(
            "notificator/success",
            this.$t("succeeded_operation")
          );
        }
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
